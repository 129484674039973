<template>
  <div class="container">
    <Header></Header>
	<div v-html="model.content"></div>
    <Footer></Footer>
     <el-backtop style="width:50px;height:50px;">
      <div style="{ height: 100%; width: 100%; background: #013656 ; box-shadow: 0 0 6px rgba(0,0,0, .12); text-align: center; z-index: 9999;}">
		<img src="https://api.starmaxintl.com/storage/file/20220715/e75486d409260e76f43ab4aafd176fd88b17e6a8.png" alt="" style="vertical-align: top;">
      </div>
    </el-backtop>
  </div>  
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { info } from '@/api/server'


export default {
  components: {
    Header,
    Footer,
  
  },
  data() {
    return {
	  model: {}
    };
  },
 created() {
    this.info()
  },
  
  mounted() {
      this.$AOS.init({
        offset: 200,  
        duration: 600,  
        easing: 'ease-in-sine',  
        delay: 100
      });
  },
  watch: {
     'model.content'(newValue, oldValue) {
	   this.$nextTick(() => {
        this.$AOS.init({
        offset: 200,  
        duration: 600,  
        easing: 'ease-in-sine',  
        delay: 100
      });
      });
     }
  },
  methods: {
    info(){
	  info().then(res => {
        this.model=res.data.data
      }).catch(() => {
      })
	},
    clickLink () {
      this.$router.push({path:'/home'});

    },

    handleChange(val) {
      console.log(val);
    }
  },
}
</script>

    <style scoped >

/deep/ .ny-main{
  overflow: hidden;
  padding: 50px 8.3vw;
}

/deep/ .ny-title{
  color: #0a3c5b;
  line-height: 1;
  font-size: 6.25vw;
  font-family: DINCound;
  font-weight: normal;
}

/deep/ .ny-con{
  width: 100%;
  margin-top: 35px;
}

/deep/ .ser-m1{
  width: 100%;
  position: relative;
  overflow: hidden;
}

/deep/ .ser-m1-right{
  float: right;
  width: 62.5%;
  position: relative;
  z-index: 1;
}

/deep/ .ser-m1-right img{
  width: 100%;
}

/deep/ .ser-m1-left{
  position: absolute;
  left: 0;
  z-index: 3;
  background: #013656;
  width: 41.5%;
  box-sizing: border-box;
  padding: 20px 2%;
  color: #fff;
  overflow: hidden;
  top: 50%;
  transform: translate(0 , -50%);
  max-height: 100%;
  transition: all 1s;
}

/deep/ .ser-m1-left p{
  line-height: 2;
  font-size: 1.042vw;
  margin: 20px 0;
}

/deep/ .ser-m2{
  margin-top: 80px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

/deep/ .ser-m2-left{
  width: 43%;
  /* float: left; */
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
}


/deep/ .ser-m3-list li img,
/deep/ .ser-m2-rightPic li img,
/deep/ .ser-m2-left img{
  width: 100%;
  vertical-align: top;
}


/deep/ .ser-m2-right{
  /* width: 57%; */
  /* float: right; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
  
}

/deep/ .ser-m2-right p{
  line-height: 2;
  font-size: 1.25vw;
  color: #000;
  margin-top: 2%;
  padding-left: 46%;
}

/deep/ .ser-m2-rightPic{
  position: relative;
  margin: 2% 0 0;
  text-align: right;
  /* height: 280px; */
}


/deep/ .ser-m2-rightPic ul{
  /* position: absolute;
  right: 0;
  top: 0;
  z-index: 3; */
  /* height: 280px; */
  white-space: nowrap;
}

/deep/ .ser-m2-rightPic li{
  width: 22%;
  /* height: 280px; */
  background: #013656;
  box-sizing: border-box;
  padding: 6px;
  display: inline-block;
  margin-left: 24px;
}

/deep/ .ser-m3{
  margin-top: 80px;
  width: 100%;
}

/deep/ .ser-m3-title{
  text-align: center;
  line-height: 1;
  font-size: 3.75vw;
  color: #0a3c5b;
  font-weight: normal;
  font-family: DINCound;
  white-space: pre-wrap;
}

/deep/ .ser-m3-subTitle{
  text-align: center;
  font-size: 0.9325vw;
  color: #000;
  line-height: 2;
  white-space: pre-wrap;
  margin-top: 25px;
}

/deep/ .ser-m3-listBox{
  margin-top: 40px;
}
/deep/ .ser-m3-list li{
  width: 100%;
  margin-bottom: 80px;
}
</style>
